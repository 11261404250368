import React from 'react'

export default function BankDetails({ info }) {
    return (
        <>
            <div className="tile_shadow bank-details">
                {`Account Info : ${info ?? "NA"}`}
            </div>
            <br />
        </>
    )
}
