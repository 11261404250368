import React, { useState } from 'react'
import { reverseNumberDisplay } from '../../utils/currencyNumberDisplay';
import { toast } from 'react-toastify';
import CurrencyConverter from '../../Currency/CurrencyConverter';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { ApiHook } from '../../hooks/apiHook';
import { getEpins } from '../../utils/getEpinList';
import _ from "lodash"

export default function EpinPayment({ setValue, formValues, setSubmitButtonActive, sponsor }) {
    const [epinValues, setEpinValues] = useState([]);
    const [epin, setEpin] = useState();
    const [addPin, setAddpin] = useState(true);


    const setCurrentEpin = (e) => {
        setEpin(e.target.value)
    }
    const userSelectedCurrency = useSelector(
        (state) => state.user?.selectedCurrency
    );
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const epinVerification = ApiHook.CallVerifyEpin()
    const handleEpinChange = async () => {
        if (_.some(epinValues, obj => _.isEqual(obj.value, epin))) {
            setEpin("")
            toast.error("Already Used")
            return
        }
        const epinStatus = await epinVerification.mutateAsync({ epin, sponsor })
        if (epinStatus.status) {
            const epins = epinValues
            const newPins = epins.push(epinStatus.data)
            setEpinValues(newPins)
            setEpin("")
        } else {
            toast.error("Invalid E-pin")
        }
        let newValues = [];
        let totalEpinAmount = 0;
        setEpinValues(epinValues);
        Object.entries(epinValues)?.map(([key, value]) => {
            totalEpinAmount =
                totalEpinAmount + reverseNumberDisplay(String(value.amount));
            newValues.push(value.value);
        });
        const balance =
            Number(reverseNumberDisplay(String(formValues?.totalAmount))) -
            Number(totalEpinAmount);

        if (balance <= 0) {
            setValue("epinBalance", 0);
            setSubmitButtonActive(false);
            toast.success("Total amount achieved");
            setAddpin(false)
        } else {
            setSubmitButtonActive(true);
            setValue("epinBalance", reverseNumberDisplay(String(balance)));
        }
        setValue("epins", newValues);
        setValue("totalEpinAmount", reverseNumberDisplay(String(totalEpinAmount)));
    };
    const removeItemByIndex = (index) => {
        let newBalance = 0;
        const remainingValues = [];
        const updatedEpinValues = [...epinValues];
        const removedItem = updatedEpinValues.splice(index, 1)[0]; // Remove and get the removed item
        setEpinValues(updatedEpinValues);
        // update epinValues
        updatedEpinValues.forEach((item) => {
            remainingValues.push(item.value);
        });
        // Recalculate totalEpinAmount and balance
        const newTotalEpinAmount =
            Number(reverseNumberDisplay(String(formValues.totalEpinAmount))) -
            Number(reverseNumberDisplay(String(removedItem.amount.toFixed(2))));

        if (newTotalEpinAmount < formValues.totalAmount) {
            newBalance =
                Number(reverseNumberDisplay(String(formValues?.totalAmount))) -
                newTotalEpinAmount;
        }
        // Update the state values
        setValue(
            "totalEpinAmount",
            reverseNumberDisplay(String(newTotalEpinAmount))
        );
        setValue("epinBalance", reverseNumberDisplay(String(newBalance)) ?? 0);
        setValue("epins", remainingValues);
        if (newBalance <= 0) {
            setSubmitButtonActive(false);
        } else {
            setSubmitButtonActive(true);
            setAddpin(true)
        }
    };

    return (
        <div className="row">
            {addPin && <>
                <div className="col-lg-6">
                    <input type='text' className='form-control' style={{ height: '46px' }} onChange={setCurrentEpin} value={epin} />
                </div>

                <div className="col-lg-6">
                    <button className="btn text-white submit-button rounded-3 bg-color-info" onClick={handleEpinChange}>Apply</button>
                </div>

            </>}


            <div className="elawwet_blance_sec">
                {epinValues.map((epinItem, index) => (
                    <div className="elawwet_blance_row" key={index}>
                        <span>{epinItem.value}</span>
                        <strong>
                            {t("amount")}: {userSelectedCurrency?.symbolLeft}
                            {CurrencyConverter(
                                epinItem.amount,
                                conversionFactor
                            )}
                        </strong>
                        <a
                            href="#/"
                            className="epin_ball_clear"
                            onClick={() => removeItemByIndex(index)} // Call the remove function with the index
                        >
                            <i className="fa fa-close"></i>
                        </a>
                    </div>
                ))}
            </div>
            <div className="valid_epi_pin_box">
                {t("valid_epin_data")}
            </div>
            <div className="total_epin_detail">
                <table>
                    <tbody>
                        <tr>
                            <td>{t("total_epin_amount")}</td>
                            <td className="text-end">
                                <strong>
                                    {userSelectedCurrency?.symbolLeft}
                                    {CurrencyConverter(
                                        formValues?.totalEpinAmount,
                                        conversionFactor
                                    ) ?? 0}
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("totalAmount")}</td>
                            <td className="text-end">
                                <strong>
                                    {userSelectedCurrency?.symbolLeft}
                                    {CurrencyConverter(
                                        formValues?.totalAmount,
                                        conversionFactor
                                    )}
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>{t("balance")}</td>
                            <td className="text-end">
                                <strong>
                                    {userSelectedCurrency?.symbolLeft}
                                    {formValues?.epinBalance !== undefined
                                        ? CurrencyConverter(
                                            formValues?.epinBalance,
                                            conversionFactor
                                        )
                                        : CurrencyConverter(
                                            formValues?.totalAmount,
                                            conversionFactor
                                        )}
                                </strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
