import React from 'react';
import { AddressElement } from '@stripe/react-stripe-js';

const AddressForm = () => {
    const options = {
        mode: 'billing',
        fields: {
            phone: 'always',
        },
        validation: {
            phone: { required: 'always' }
        }
    }
    return (
        <form>
            <h5>Billing address</h5>
            <hr />
            <AddressElement options={options} />
        </form>
    );
};

export default AddressForm;
