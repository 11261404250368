import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import RegisterModal from "../Common/modals/RegisterModal";

const CheckoutOrderSummary = ({ handleNext, totalAmount, items, termsAndConditionMsg, refundPolicyMsg }) => {
  const [termsAndCondition, setTermsAndCondition] = useState(false)
  const [refundPolicy, setRefundPolicy] = useState(false)
  const [modalView, setModalView] = useState(false)
  const [modalType, setModalType] = useState("termsAndConditions")
  const [showPolicyError, setShowPolicyError] = useState(false);

  const showPopup = (type) => {
    setModalView(!modalView)
    setModalType(type)
  }
  const handleTerms = (e) => {
    const { name, checked } = e.target
    if (name === "termsAndConditions") setTermsAndCondition(checked)
    if (name === "refundPolicy") setRefundPolicy(checked)
  }
  const { t } = useTranslation();
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  return (
    <>
      <div className="checkout_list_scrl">
        {items?.map((product, index) => (
          <div
            className="checkout_contant_cart_row checkout_odr_summry"
            key={index}
          >
            <div className="checkout_cnt_image">
              <img src={product.image ?? "/images/product2.jpg"} alt="" />
            </div>
            <div className="checkout_cnt_product">
              <span>{t("product_name")}</span>
              <strong>{product.name}</strong>
            </div>
            <div className="checkout_cnt_qty">
              <span>{t("quantity")}</span>
              <div className="checkout_cnt_qty_btn">{product.quantity}</div>
            </div>
            <div className="checkout_cnt_ttl_amnt">
              <span>{`${t("totalAmount")}: `}</span>
              <strong>{`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(
                product.price * product.quantity,
                conversionFactor
              )}`}</strong>
            </div>
          </div>
        ))}
      </div>
      <div className="checkout_cnt_ttl_amnt">
        <span>{`${t("totalAmount")}: `}</span>
        <strong>{`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(
          totalAmount,
          conversionFactor
        )}`}</strong>
      </div>
      <div className="col-md-12">
        <div className="regsiter_form_box_tandc">
          <label className="d-flex" htmlFor="box">
            <input
              name={"termsAndConditions"}
              type="checkbox"
              id="box"
              style={{ marginRight: "5px" }}
              onChange={(e) => handleTerms(e)}
            />
          </label>
          <a
            data-bs-toggle="modal"
            className="pop_terms_btn"
            onClick={() => showPopup("termsAndConditions")}
          >
            {t("iAcceptTermsAndConditions")}
            <span className="text-danger"> ٭ </span>
          </a>
          {
            termsAndCondition === false && showPolicyError && (
              <span className="error-message-validator">
                *{t("required")}
              </span>
            )}
        </div>
      </div>
      <div className="col-md-12">
        <div className="regsiter_form_box_tandc">
          <label className="d-flex" htmlFor="box">
            <input
              name={"refundPolicy"}
              type="checkbox"
              id="box"
              style={{ marginRight: "5px" }}
              onChange={(e) => handleTerms(e)}
            />
          </label>
          <a
            data-bs-toggle="modal"
            className="pop_terms_btn"
            onClick={(e) => showPopup("refundPolicy")}
          >
            {t("refundPolicyAccept")}
            <span className="text-danger"> ٭ </span>
          </a>
          {
            refundPolicy === false && showPolicyError && (
              <span className="error-message-validator">
                *{t("required")}
              </span>
            )}
        </div>
      </div>

      <div className="checkout_continuew_btn">
        {!termsAndCondition || !refundPolicy ? <button
          className="btn btn-primary checkout_cnt_btn"
          onClick={() => setShowPolicyError(true)}
        >
          {t("continue")}
        </button> :
          <button
            className="btn btn-primary checkout_cnt_btn"
            onClick={handleNext}
          >
            {t("continue")}
          </button>
        }
      </div>
      <RegisterModal
        show={modalView}
        handleClose={showPopup}
        data={modalType === "termsAndConditions" ? termsAndConditionMsg : refundPolicyMsg}
        type={modalType}
      />
    </>
  );
};

export default CheckoutOrderSummary;
