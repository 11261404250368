// const BASE_URL = "http://192.168.21.19:5000/node-api/";
// const DEFAULT_KEY = '742f49f9-8844-4138-b179-789a306c246a'

const BASE_URL = "https://admin.roncapua.iossmlm.com/node-api/"
const DEFAULT_KEY = "742f49f9-8844-4138-b179-789a306c246a"

// const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

//@dev
const PAYPAL_CLIENT_ID = "AaKx9GmWNb6aB4ZgFQc7CmXBBOuwQvO6_V8xLlfkeiH8nzwm731hTptX4CGwkW9SqkYrolOeR6CFyMVL"


export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
